/* eslint-disable react/prop-types */
import React, { createContext, useContext } from 'react';
import { Story_Play_Type } from '@/baseType';

interface IPlayTypeContext {
  playType?: Story_Play_Type;
}

interface IPlayTypeProvider {
  children: JSX.Element | Array<JSX.Element>;
  playType?: Story_Play_Type;
}

const initialState = {};

const PlayTypeContext = createContext<IPlayTypeContext>(initialState);

const PlayTypeProvider = ({ children, playType }: IPlayTypeProvider) => {
  return (
    <PlayTypeContext.Provider value={{ playType }}>
      {children}
    </PlayTypeContext.Provider>
  );
};

export const usePlayTypeContext = () => {
  const state = useContext(PlayTypeContext);
  if (!state) throw new Error('PlayTypeContext is undefined!');
  return state;
};

export default PlayTypeProvider;
