import React, { SVGProps } from 'react'

export const StorygamePlayCircleIcon = ({
  ...props
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="icPlay">
        <circle
          id="Ellipse 2"
          cx="39.9997"
          cy="40.0002"
          r="36.6667"
          fill="black"
          fillOpacity="0.5"
        />
        <path
          id="ic24Play"
          d="M32.5 28.5644C32.5 27.016 34.3004 26.1215 35.5839 27.0322L51.7003 38.4677C52.7666 39.2243 52.7666 40.7754 51.7003 41.532L35.5839 52.9674C34.3004 53.8781 32.5 52.9836 32.5 51.4353V28.5644Z"
          fill="white"
        />
      </g>
    </svg>
  )
}
