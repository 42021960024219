import React, { useRef, useState } from 'react'
import styles from './SlideBannerList.module.scss'
import { Story } from '../../../../types/storyGame'
import { SlideBannerItem } from '../item/SlideBannerItem'
import { ArrowCircleLeftIcon } from '../../../../assets/svgs/ArrowCircleLeftIcon'
import { ArrowCircleRightIcon } from '../../../../assets/svgs/ArrowCircleRightIcon'

interface ISlideBannerList {
  sectionTitle?: string
  storyList?: Story[]
  onClickStoryItem: ({
    storyId,
    storyName,
    storyBundleId,
    url,
  }: {
    storyId?: number
    storyName?: string
    storyBundleId?: number
    url?: string
  }) => void
  onStoryItemExposed: ({
    storyId,
    storyName,
    storyBundleId,
  }: {
    storyId?: number
    storyName?: string
    storyBundleId?: number
  }) => void
}

export const SlideBannerList = ({
  storyList,
  sectionTitle,
  onClickStoryItem,
  onStoryItemExposed,
}: ISlideBannerList) => {
  const carouselRef = useRef<HTMLDivElement>(null)
  const [currentIndex, setCurrentIndex] = useState(1)

  const handleArrowButtonClick = (direction: 'left' | 'right') => {
    const sliderLength = storyList?.length || 0
    let index = currentIndex

    index =
      direction === 'left'
        ? index === 1
          ? sliderLength
          : index - 1
        : index === sliderLength
        ? 1
        : index + 1
    setCurrentIndex(index)

    carouselRef.current?.style.setProperty(
      'transform',
      `translateX(-${
        ((index - 1) * carouselRef.current.clientWidth) / sliderLength
      }px)`
    )
  }

  return (
    <div>
      <div className={styles.container}>
        <div className={styles.sectionTitle}>{sectionTitle}</div>
        {/* eslint-disable-next-line jsx-a11y/role-has-required-aria-props */}
        <div className={styles.carouselOuter} role="slider">
          {storyList && (
            <div
              className={styles.carouselList}
              ref={carouselRef}
              style={{ width: `calc(100% * ${storyList.length})` }}
            >
              {storyList.map((story, index) => (
                <SlideBannerItem
                  data={story}
                  key={story.storyId || '' + index}
                  onClickStoryItem={onClickStoryItem}
                  onStoryItemExposed={onStoryItemExposed}
                />
              ))}
            </div>
          )}
        </div>
        {currentIndex > 1 && (
          <div
            className={`${styles.storyCarouselArrow} ${styles.left}`}
            onClick={() => handleArrowButtonClick('left')}
          >
            <ArrowCircleLeftIcon width={32} height={32} />
          </div>
        )}
        {currentIndex < (storyList?.length || 0) && (
          <div
            className={`${styles.storyCarouselArrow} ${styles.right}`}
            onClick={() => handleArrowButtonClick('right')}
          >
            <ArrowCircleRightIcon width={32} height={32} />
          </div>
        )}
      </div>
    </div>
  )
}
