import * as Types from '../../../../baseType';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type StoryGameSectionViewFragment = { __typename?: 'StorygameSectionView', sectionTitle?: string | null, sectionId: number, showReleaseDate: boolean, hasViewMore: boolean, isTestSection: boolean, uiType: Types.StorygameSectionUiType, sectionData: string, sectionType?: Types.StorygameSectionType | null };

export type GetAllStoryGameSectionViewsQueryVariables = Types.Exact<{
  data: Types.GetAllStorygameSectionViewsInput;
}>;


export type GetAllStoryGameSectionViewsQuery = { __typename?: 'Query', getAllStorygameSectionViews: Array<{ __typename?: 'StorygameSectionView', sectionTitle?: string | null, sectionId: number, showReleaseDate: boolean, hasViewMore: boolean, isTestSection: boolean, uiType: Types.StorygameSectionUiType, sectionData: string, sectionType?: Types.StorygameSectionType | null }> };

export const StoryGameSectionViewFragmentDoc = gql`
    fragment StoryGameSectionView on StorygameSectionView {
  sectionTitle
  sectionId
  showReleaseDate
  hasViewMore
  isTestSection
  uiType
  sectionData
  sectionType
}
    `;
export const GetAllStoryGameSectionViewsDocument = gql`
    query getAllStoryGameSectionViews($data: GetAllStorygameSectionViewsInput!) {
  getAllStorygameSectionViews(data: $data) {
    ...StoryGameSectionView
  }
}
    ${StoryGameSectionViewFragmentDoc}`;

/**
 * __useGetAllStoryGameSectionViewsQuery__
 *
 * To run a query within a React component, call `useGetAllStoryGameSectionViewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllStoryGameSectionViewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllStoryGameSectionViewsQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetAllStoryGameSectionViewsQuery(baseOptions: Apollo.QueryHookOptions<GetAllStoryGameSectionViewsQuery, GetAllStoryGameSectionViewsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllStoryGameSectionViewsQuery, GetAllStoryGameSectionViewsQueryVariables>(GetAllStoryGameSectionViewsDocument, options);
      }
export function useGetAllStoryGameSectionViewsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllStoryGameSectionViewsQuery, GetAllStoryGameSectionViewsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllStoryGameSectionViewsQuery, GetAllStoryGameSectionViewsQueryVariables>(GetAllStoryGameSectionViewsDocument, options);
        }
export type GetAllStoryGameSectionViewsQueryHookResult = ReturnType<typeof useGetAllStoryGameSectionViewsQuery>;
export type GetAllStoryGameSectionViewsLazyQueryHookResult = ReturnType<typeof useGetAllStoryGameSectionViewsLazyQuery>;
export type GetAllStoryGameSectionViewsQueryResult = Apollo.QueryResult<GetAllStoryGameSectionViewsQuery, GetAllStoryGameSectionViewsQueryVariables>;