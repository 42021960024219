import React, { useEffect, useRef, useState } from 'react'
import styles from './SlideBannerItem.module.scss'
import { StorygamePlayCircleIcon } from '../../../../assets/svgs/StorygamePlayCircleIcon'
import { StorygamePlayIcon } from '../../../../assets/svgs/StorygamePlayIcon'
import { Story } from '../../../../types/storyGame'
import { checkDevice } from '../../../../utils/checkDevice'

interface ISlideBannerItem {
  data: Story
  onClickStoryItem: ({
    storyId,
    storyName,
    storyBundleId,
    url,
  }: {
    storyId?: number
    storyName?: string
    storyBundleId?: number
    url?: string
  }) => void
  onStoryItemExposed: ({
    storyId,
    storyName,
    storyBundleId,
  }: {
    storyId?: number
    storyName?: string
    storyBundleId?: number
  }) => void
}

const EmptyMediaItem = {
  storyPreviewId: 0,
  link: '',
  fileType: 'Image',
}

export const SlideBannerItem = ({
  data,
  onClickStoryItem,
  onStoryItemExposed,
}: ISlideBannerItem) => {
  const [currentPreview, setCurrentMedia] = useState(data.previews?.[0])
  const [showPlayIcon, setShowPlayIcon] = useState(true)
  const videoRef = useRef<HTMLVideoElement>(null)

  const storyItemRef = React.useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!storyItemRef.current) return
    const observer = new IntersectionObserver(entries => {
      entries.forEach(
        entry => {
          if (entry.isIntersecting && data.bannerType !== 'Link') {
            onStoryItemExposed({
              storyId: data.storyId,
              storyName: data.title,
              storyBundleId: data.bundleId,
            })
          }
        },
        {
          threshold: 1,
        }
      )
    })

    observer.observe(storyItemRef.current)
    return () => observer.disconnect()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (currentPreview?.fileType === 'Video') {
      if (checkDevice() === 'PC') {
        videoRef.current?.play()
      }
      // 브라우저 정책으로 페이지 초기 진입 시 자동재생이 동작하지 않는 경우가 있음.
      if (!videoRef.current?.paused) {
        // 위 `videoRef.current?.play()` 코드를 통해 정상적으로 동영상이 실행된 경우에는 play아이콘 숨기기
        setShowPlayIcon(false)
      } else {
        // 위 `videoRef.current?.play()` 코드가 실행되었음에도 동영상 재생에 실패한 경우에는 play아이콘 보여주기
        setShowPlayIcon(true)
      }
    }
  }, [currentPreview])

  const handleVideoClick = () => {
    if (videoRef.current?.paused) {
      videoRef.current?.play()
      setShowPlayIcon(false)
    } else {
      videoRef.current?.pause()
      setShowPlayIcon(true)
    }
  }

  const previewListWithEmptyItem =
    (data.previews?.length || 0) >= 3
      ? data.previews
      : [...(data.previews || []), ...new Array(3).fill(EmptyMediaItem)]

  return (
    <div className={styles.storyContainer}>
      <div
        ref={storyItemRef}
        className={`${styles.mainMediaWrapper} ${
          currentPreview?.fileType === 'Video' ? styles.video : ''
        }`}
        onClick={() =>
          onClickStoryItem({
            storyId: data.storyId,
            url: data.url,
            storyName: data.title,
            storyBundleId: data.bundleId,
          })
        }
      >
        {currentPreview?.fileType === 'Video' && showPlayIcon && (
          <div className={styles.playerIconWrapper}>
            <StorygamePlayCircleIcon
              onClick={handleVideoClick}
              width={80}
              height={80}
            />
          </div>
        )}
        {currentPreview?.fileType === 'Image' ? (
          <img src={currentPreview.link} alt="" className={styles.image} />
        ) : (
          <video
            className={styles.video}
            src={currentPreview?.link}
            ref={videoRef}
            onClickCapture={handleVideoClick}
          />
        )}
        {data.bannerType === 'Link' && (
          <img src={data.mainImageLink} alt="" className={styles.image} />
        )}
      </div>
      <div
        className={`${styles.contentWrapper} ${
          data.bannerType === 'Link' && styles.center
        }`}
      >
        <div
          className={styles.infoWrapper}
          onClick={() =>
            onClickStoryItem({ storyId: data.storyId, url: data.url })
          }
        >
          <div className={styles.title}>{data.title}</div>
          <div className={styles.subTitle}>{data?.subTitle}</div>
          <div className={styles.description}>{data.description}</div>
        </div>
        {data.bannerType === 'Link' ? (
          <button
            className={styles.linkButton}
            onClick={() => onClickStoryItem({ url: data.url })}
          >
            {data.buttonName}
          </button>
        ) : (
          <div className={styles.mediaContainer}>
            {previewListWithEmptyItem?.slice(0, 3).map((media, index) => {
              return (
                <div
                  className={`${styles.previewMediaWrapper} ${
                    currentPreview?.storyPreviewId === media.storyPreviewId
                      ? styles.selected
                      : ''
                  }`}
                  key={index}
                  onClick={() => setCurrentMedia(media)}
                >
                  {media.fileType === 'Video' && (
                    <StorygamePlayIcon
                      width={24}
                      height={24}
                      className={styles.thumbnailPlayIcon}
                    />
                  )}
                  {media.fileType === 'Image' ? (
                    <>{media.link && <img src={media.link} alt="preview" />}</>
                  ) : (
                    <video src={media.link} width={144} height={81} />
                  )}
                </div>
              )
            })}
          </div>
        )}
      </div>
    </div>
  )
}
