import React from 'react'
import styles from './EmptyComponent.module.scss'
import { t } from '../../utils/translate'

export const EmptyComponent = () => {
  return (
    <div className={styles.container}>
      <span className={styles.title}>
        {t('storygame_tab_screen_title_coming_soon')}
      </span>
      <span className={styles.description}>
        {t('storygame_tab_screen_description_coming_soon')}
      </span>
    </div>
  )
}
