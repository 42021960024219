import React, { SVGProps } from 'react'

export const ArrowCircleLeftIcon = ({ ...props }: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      {...props}
    >
      <g filter="url(#filter0_b_8337_38797)">
        <circle
          cx="15.9997"
          cy="16"
          r="14.6667"
          fill="white"
          fillOpacity="0.1"
        />
        <path
          d="M17.3333 10.6667L12 16L17.3333 21.3333"
          stroke="white"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_b_8337_38797"
          x="-16"
          y="-16"
          width="64"
          height="64"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="8" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_8337_38797"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_8337_38797"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  )
}
