import React from 'react'
import {
  CAROUSEL_SIZES,
  Section,
  STORY_GAME_SECTION_UI_TYPE,
} from '../../types/storyGame'
import { CarouselComponent } from './CarouselComponent'
import { CarouselRankComponent } from './CarouselRankComponent'
import { RankingListComponent } from './RankingListComponent'
import { SliderComponent } from './SliderComponent'
import { EmptyComponent } from './EmptyComponent'

export const StoryGameSectionComponent = ({
  data,
  onClickStoryItem = () => {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
  },
  onClickViewMore = () => {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
  },
  onStoryItemExposed = () => {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
  },
}: {
  data?: Section[]
  onClickStoryItem?: ({
    storyId,
    url,
    sectionId,
    isTestSection,
    sectionName,
    sectionIndex,
    storyName,
    storyBundleId,
  }: {
    storyId?: number
    url?: string
    sectionId?: number
    isTestSection?: boolean
    sectionName?: string
    sectionIndex?: number
    storyName?: string
    storyBundleId?: number
  }) => void
  onClickViewMore?: ({
    sectionId,
    sectionColumn,
    sectionIndex,
    tagName,
  }: {
    sectionId?: number
    sectionColumn?: number
    sectionIndex?: number
    tagName?: string
  }) => void
  onStoryItemExposed?: ({
    storyId,
    sectionId,
    isTestSection,
    sectionName,
    sectionIndex,
    storyName,
    storyBundleId,
  }: {
    storyId?: number
    sectionId?: number
    isTestSection: boolean
    sectionName?: string
    sectionIndex?: number
    storyName?: string
    storyBundleId?: number
  }) => void
}) => {
  const isEmptyTab =
    !data?.length ||
    data?.every(
      section =>
        section.storyList?.length === 0 ||
        (section.sectionList &&
          section.sectionList.every(section => section.storyList?.length === 0))
    )

  return (
    <>
      {!isEmptyTab ? (
        data?.map((section, index) => {
          if (
            section.storyList?.length === 0 ||
            (section.sectionList &&
              section.sectionList.every(
                section => section.storyList?.length === 0
              ))
          ) {
            return null
          }

          if (section.uiType === STORY_GAME_SECTION_UI_TYPE.Slider) {
            return (
              <SliderComponent
                key={section.title + String(index)}
                storyList={section.storyList}
                sectionTitle={section.title}
                onClickStoryItem={args => {
                  onClickStoryItem({
                    sectionId: section.sectionId,
                    isTestSection: section.isTestSection,
                    sectionName: section.title,
                    sectionIndex: index,
                    ...args,
                  })
                }}
                onStoryItemExposed={args => {
                  onStoryItemExposed({
                    sectionId: section.sectionId,
                    isTestSection: section.isTestSection,
                    sectionName: section.title,
                    sectionIndex: index,
                    ...args,
                  })
                }}
              />
            )
          }

          if (section.uiType === STORY_GAME_SECTION_UI_TYPE.CarouselSmall) {
            return (
              <CarouselComponent
                key={section.title + String(index)}
                sectionTitle={section.title}
                recommendedTargetText={section.recommendedTargetText}
                storyList={section.storyList}
                hasViewMore={section.hasViewMore}
                size={CAROUSEL_SIZES.Small}
                onClickStoryItem={(args: { storyId?: number; url?: string }) =>
                  onClickStoryItem({
                    sectionId: section.sectionId,
                    isTestSection: section.isTestSection,
                    sectionName: section.title,
                    sectionIndex: index,
                    ...args,
                  })
                }
                onClickViewMore={() =>
                  onClickViewMore({
                    sectionId: section.sectionId,
                    sectionIndex: index,
                    tagName: section.recommendedTargetText,
                  })
                }
                onStoryItemExposed={args =>
                  onStoryItemExposed({
                    sectionId: section.sectionId,
                    isTestSection: section.isTestSection,
                    sectionName: section.title,
                    sectionIndex: index,
                    ...args,
                  })
                }
              />
            )
          }

          if (section.uiType === STORY_GAME_SECTION_UI_TYPE.CarouselMedium) {
            return (
              <CarouselComponent
                key={section.title + String(index)}
                sectionTitle={section.title}
                recommendedTargetText={section.recommendedTargetText}
                storyList={section.storyList}
                hasViewMore={section.hasViewMore}
                size={CAROUSEL_SIZES.Medium}
                onClickStoryItem={args =>
                  onClickStoryItem({
                    sectionId: section.sectionId,
                    isTestSection: section.isTestSection,
                    sectionName: section.title,
                    sectionIndex: index,
                    ...args,
                  })
                }
                onClickViewMore={() =>
                  onClickViewMore({
                    sectionId: section.sectionId,
                    sectionIndex: index,
                    tagName: section.recommendedTargetText,
                  })
                }
                onStoryItemExposed={args =>
                  onStoryItemExposed({
                    sectionId: section.sectionId,
                    isTestSection: section.isTestSection,
                    sectionName: section.title,
                    sectionIndex: index,
                    ...args,
                  })
                }
              />
            )
          }

          if (section.uiType === STORY_GAME_SECTION_UI_TYPE.CarouselLarge) {
            return (
              <CarouselComponent
                key={section.title + String(index)}
                sectionTitle={section.title}
                recommendedTargetText={section.recommendedTargetText}
                storyList={section.storyList}
                hasViewMore={section.hasViewMore}
                size={CAROUSEL_SIZES.Large}
                onClickStoryItem={args =>
                  onClickStoryItem({
                    sectionId: section.sectionId,
                    isTestSection: section.isTestSection,
                    sectionName: section.title,
                    sectionIndex: index,
                    ...args,
                  })
                }
                onClickViewMore={() =>
                  onClickViewMore({
                    sectionId: section.sectionId,
                    sectionIndex: index,
                    tagName: section.recommendedTargetText,
                  })
                }
                onStoryItemExposed={args =>
                  onStoryItemExposed({
                    sectionId: section.sectionId,
                    isTestSection: section.isTestSection,
                    sectionName: section.title,
                    sectionIndex: index,
                    ...args,
                  })
                }
              />
            )
          }

          if (section.uiType === STORY_GAME_SECTION_UI_TYPE.CarouselProgress) {
            return (
              <CarouselComponent
                key={section.title + String(index)}
                sectionTitle={section.title}
                storyList={section.storyList}
                showProgress={true}
                hasViewMore={section.hasViewMore}
                size={CAROUSEL_SIZES.Medium}
                onClickStoryItem={args =>
                  onClickStoryItem({
                    sectionId: section.sectionId,
                    isTestSection: section.isTestSection,
                    sectionName: section.title,
                    sectionIndex: index,
                    ...args,
                  })
                }
                onClickViewMore={() =>
                  onClickViewMore({
                    sectionId: section.sectionId,
                    sectionIndex: index,
                  })
                }
                onStoryItemExposed={args =>
                  onStoryItemExposed({
                    sectionId: section.sectionId,
                    isTestSection: section.isTestSection,
                    sectionName: section.title,
                    sectionIndex: index,
                    ...args,
                  })
                }
              />
            )
          }

          if (section.uiType === STORY_GAME_SECTION_UI_TYPE.RankList) {
            return (
              <RankingListComponent
                key={section.title + String(index)}
                sectionTitle={section.title}
                list={section.sectionList}
                onClickStoryItem={args => {
                  onClickStoryItem({
                    sectionId: section.sectionId,
                    isTestSection: section.isTestSection,
                    sectionIndex: index,
                    ...args,
                  })
                }}
                onClickViewMore={({ sectionColumn }) => {
                  onClickViewMore({
                    sectionId: section.sectionId,
                    sectionIndex: index,
                    sectionColumn,
                  })
                }}
                onStoryItemExposed={args =>
                  onStoryItemExposed({
                    sectionId: section.sectionId,
                    isTestSection: section.isTestSection,
                    sectionIndex: index,
                    ...args,
                  })
                }
              />
            )
          }

          if (section.uiType === STORY_GAME_SECTION_UI_TYPE.CarouselRank) {
            return (
              <CarouselRankComponent
                key={section.title + String(index)}
                sectionTitle={section.title}
                storyList={section.storyList}
                hasViewMore={section.hasViewMore}
                onClickStoryItem={args => {
                  onClickStoryItem({
                    sectionId: section.sectionId,
                    isTestSection: section.isTestSection,
                    sectionName: section.title,
                    sectionIndex: index,
                    ...args,
                  })
                }}
                onClickViewMore={() =>
                  onClickViewMore({
                    sectionId: section.sectionId,
                    sectionIndex: index,
                  })
                }
                onStoryItemExposed={args =>
                  onStoryItemExposed({
                    sectionId: section.sectionId,
                    isTestSection: section.isTestSection,
                    sectionName: section.title,
                    sectionIndex: index,
                    ...args,
                  })
                }
              />
            )
          }
        })
      ) : (
        <EmptyComponent />
      )}
    </>
  )
}
