import React from 'react'
import styles from './CarouselComponent.module.scss'
import { CAROUSEL_SIZES, CarouselSizes, Story } from '../../types/storyGame'
import { Carousel } from './common/list/Carousel'
import { CarouselItem } from './common/item/CarouselItem'
import { t } from '../../utils/translate'
import { replaceJosaWithValue } from '../../utils/replaceJosaWithValue'

interface ICarouselComponent {
  sectionTitle?: string
  recommendedTargetText?: string
  storyList?: Story[]
  showProgress?: boolean
  size?: CarouselSizes
  hasViewMore?: boolean
  onClickStoryItem: ({
    storyId,
    storyName,
    storyBundleId,
    url,
  }: {
    storyId?: number
    storyName?: string
    storyBundleId?: number
    url?: string
  }) => void
  onClickViewMore?: () => void
  onStoryItemExposed: ({
    storyId,
    storyName,
    storyBundleId,
  }: {
    storyId?: number
    storyName?: string
    storyBundleId?: number
  }) => void
}

// {{value}}, {{ value }}, {{ value}} 등의 형태로 들어오는 경우를 모두 대응하기 위한 정규식
const VALUE_REGEX = /({{\s*value\s*}})/g

// 캐로셀형 섹션 UI
export const CarouselComponent = ({
  sectionTitle,
  recommendedTargetText,
  storyList,
  showProgress,
  size = CAROUSEL_SIZES.Large,
  hasViewMore,
  onClickStoryItem,
  onClickViewMore,
  onStoryItemExposed,
}: ICarouselComponent) => {
  const titleParts = replaceJosaWithValue({
    message: sectionTitle || '',
    value: recommendedTargetText,
  })?.split(VALUE_REGEX)

  return (
    <div
      className={styles.container}
      data-story-game-section-title={sectionTitle}
    >
      <div className={styles.sectionHeader}>
        <p className={styles.sectionTitle}>
          {titleParts?.map((part, index) =>
            VALUE_REGEX.test(part) ? (
              <span className={styles.point} key={part + index}>
                {recommendedTargetText}
              </span>
            ) : (
              <span key={part + index}>{part}</span>
            )
          )}
        </p>
        {hasViewMore && (
          <div className={styles.viewMoreButton} onClick={onClickViewMore}>
            {t('storygame_detail_screen_label_view_more')}
          </div>
        )}
      </div>
      <Carousel>
        <>
          {storyList?.map((story, index) => (
            <CarouselItem
              key={story.storyId || '' + index}
              story={story}
              size={size}
              showProgress={showProgress}
              onClickStoryItem={onClickStoryItem}
              onStoryItemExposed={onStoryItemExposed}
            />
          ))}
        </>
      </Carousel>
    </div>
  )
}
