import { gql, QueryHookOptions } from '@apollo/client';
import {
  useGetAllStoryGameSectionViewsQuery as useQuery,
  GetAllStoryGameSectionViewsQueryVariables,
  GetAllStoryGameSectionViewsQuery,
} from './__generated__/useGetAllStoryGameSectionViews.generated';
import { getFormattedStoryGameSectionViewData } from '@/lib/common-react/services/storyGame';

export const STORY_GAME_SECTION_VIEW = gql`
  fragment StoryGameSectionView on StorygameSectionView {
    sectionTitle
    sectionId
    showReleaseDate
    hasViewMore
    isTestSection
    uiType
    sectionData
    sectionType
  }
`;

export const ALL_STORY_GAME_SECTION_VIEWS = gql`
  query getAllStoryGameSectionViews($data: GetAllStorygameSectionViewsInput!) {
    getAllStorygameSectionViews(data: $data) {
      ...StoryGameSectionView
    }
  }
  ${STORY_GAME_SECTION_VIEW}
`;

export const useGetAllStoryGameSectionViewsQuery = (
  options: QueryHookOptions<
    GetAllStoryGameSectionViewsQuery,
    GetAllStoryGameSectionViewsQueryVariables
  >,
) => {
  const { data, loading, refetch } = useQuery(options);

  const formattedData = data && getFormattedStoryGameSectionViewData(data);

  return { data: formattedData, loading, refetch };
};
