import React, { SVGProps } from 'react'

export const AdultIcon = ({ ...props }: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 17"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.45229 1.33026C8.18806 1.11273 7.8126 1.11273 7.54836 1.33026C6.33903 2.32581 5.01046 3.15742 3.59525 3.80467L2.0387 4.51655C1.79649 4.62733 1.64835 4.88309 1.66888 5.15503L2.09914 10.8518C2.17647 11.8756 2.73849 12.7946 3.602 13.3093L7.69646 15.7498C7.88441 15.8618 8.11624 15.8618 8.30419 15.7498L12.3987 13.3093C13.2622 12.7946 13.8242 11.8756 13.9015 10.8518L14.3318 5.15503C14.3523 4.88309 14.2042 4.62733 13.9619 4.51655L12.4054 3.80467C10.9902 3.15742 9.66162 2.32581 8.45229 1.33026ZM9.66699 7.50045C9.66699 8.42092 8.9208 9.16711 8.00033 9.16711C7.07985 9.16711 6.33366 8.42092 6.33366 7.50045C6.33366 6.57997 7.07985 5.83378 8.00033 5.83378C8.9208 5.83378 9.66699 6.57997 9.66699 7.50045ZM11.7421 11.5172C11.9436 11.7443 11.934 12.0912 11.6955 12.2791C11.6244 12.3351 11.5501 12.3871 11.4727 12.435L8.24022 14.4317C8.09184 14.5234 7.90881 14.5234 7.76043 14.4317L4.52796 12.435C4.45055 12.3871 4.37622 12.3351 4.30516 12.2791C4.06669 12.0912 4.0571 11.7443 4.25856 11.5172C5.17453 10.4846 6.51143 9.83378 8.00033 9.83378C9.48922 9.83378 10.8261 10.4846 11.7421 11.5172Z"
        fill="#FF970C"
      />
    </svg>
  )
}
