import React, { useEffect } from 'react'
import styles from './CarouselItem.module.scss'
import {
  CAROUSEL_SIZES,
  CarouselSizes,
  Story,
} from '../../../../types/storyGame'
import { AdultIcon } from '../../../../assets/svgs/AdultIcon'
import { t } from '../../../../utils/translate'

interface ICarouselItem {
  story: Story
  showProgress?: boolean
  size?: CarouselSizes
  onClickStoryItem: ({
    storyId,
    storyName,
    storyBundleId,
    url,
  }: {
    storyId?: number
    storyName?: string
    storyBundleId?: number
    url?: string
  }) => void
  onStoryItemExposed: ({
    storyId,
    storyName,
    storyBundleId,
  }: {
    storyId?: number
    storyName?: string
    storyBundleId?: number
  }) => void
}

export const CarouselItem = ({
  story,
  showProgress,
  size = CAROUSEL_SIZES.Large,
  onClickStoryItem,
  onStoryItemExposed,
}: ICarouselItem) => {
  const storyItemRef = React.useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!storyItemRef.current) return
    const observer = new IntersectionObserver(entries => {
      entries.forEach(
        entry => {
          if (entry.isIntersecting && story.bannerType !== 'Link') {
            onStoryItemExposed({
              storyId: story.storyId,
              storyName: story.title,
              storyBundleId: story.bundleId,
            })
          }
        },
        {
          threshold: 1,
        }
      )
    })

    observer.observe(storyItemRef.current)
    return () => observer.disconnect()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // progress 의 범위가 벗어나는 것을 방지합니다.
  const chapterProgress = Math.max(0, Math.min(100, story.chapterProgress ?? 0))
  return (
    <div
      className={`${styles.container} ${styles[size.toLowerCase()]}`}
      onClick={() =>
        onClickStoryItem({
          storyId: story.storyId,
          url: story.url,
          storyName: story.title,
          storyBundleId: story.bundleId,
        })
      }
      ref={storyItemRef}
    >
      <div className={styles.imageWrapper}>
        {story?.mainImageLink && (
          <img
            className={styles.image}
            src={story?.mainImageLink}
            alt={'carousel'}
          />
        )}
      </div>
      <div className={`${styles.titleWrapper} ${styles[size.toLowerCase()]}`}>
        {story.isAdult && (
          <div className={styles.adultIcon}>
            <AdultIcon width={16} height={17} />
          </div>
        )}
        <h3 className={styles.title}>{story?.title}</h3>
      </div>
      {story?.subTitle && <p className={styles.subTitle}>{story?.subTitle}</p>}
      {story?.description && (
        <span className={styles.description}>{story?.description}</span>
      )}
      {showProgress && (
        <>
          <div className={styles.progressLabelWrapper}>
            <span className={`${styles.progressLabel} ${styles.active}`}>
              {t('storygame_screen_label_progress', { value: chapterProgress })}
            </span>
          </div>
          <div className={styles.progressWrapper}>
            <div className={styles.progressBackground} />
            <div
              className={`${styles.progressBar}`}
              style={{
                width: `${chapterProgress}%`,
              }}
            />
          </div>
        </>
      )}
    </div>
  )
}
