import * as Types from '../../../../baseType';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetAllStoryGameTabsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetAllStoryGameTabsQuery = { __typename?: 'Query', getAllStorygameTabs: Array<{ __typename?: 'StorygameTab', storygameTabId: number, displayTitle: string, titleLokaliseKey: string, type: Types.StorygameTabType, genre?: string | null }> };


export const GetAllStoryGameTabsDocument = gql`
    query getAllStoryGameTabs {
  getAllStorygameTabs {
    storygameTabId
    displayTitle
    titleLokaliseKey
    type
    genre
  }
}
    `;

/**
 * __useGetAllStoryGameTabsQuery__
 *
 * To run a query within a React component, call `useGetAllStoryGameTabsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllStoryGameTabsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllStoryGameTabsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllStoryGameTabsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllStoryGameTabsQuery, GetAllStoryGameTabsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllStoryGameTabsQuery, GetAllStoryGameTabsQueryVariables>(GetAllStoryGameTabsDocument, options);
      }
export function useGetAllStoryGameTabsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllStoryGameTabsQuery, GetAllStoryGameTabsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllStoryGameTabsQuery, GetAllStoryGameTabsQueryVariables>(GetAllStoryGameTabsDocument, options);
        }
export type GetAllStoryGameTabsQueryHookResult = ReturnType<typeof useGetAllStoryGameTabsQuery>;
export type GetAllStoryGameTabsLazyQueryHookResult = ReturnType<typeof useGetAllStoryGameTabsLazyQuery>;
export type GetAllStoryGameTabsQueryResult = Apollo.QueryResult<GetAllStoryGameTabsQuery, GetAllStoryGameTabsQueryVariables>;