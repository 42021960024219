import React, { useEffect, useRef } from 'react'
import { Story } from '../../types/storyGame'
import styles from './RankingListComponent.module.scss'
import { StoryItemTitleWithRanking } from './common/item/StoryItemTitleWithRanking'
import { t } from '../../utils/translate'

interface IRankingListComponent {
  list?: {
    title?: string
    hasViewMore: boolean
    storyList?: Story[]
  }[]
  onClickStoryItem: ({
    storyId,
    storyName,
    storyBundleId,
    sectionName,
  }: {
    storyId?: number
    storyName?: string
    storyBundleId?: number
    sectionName?: string
  }) => void
  sectionTitle?: string
  onClickViewMore: ({ sectionColumn }: { sectionColumn: number }) => void
  onStoryItemExposed: ({
    storyId,
    storyName,
    storyBundleId,
    sectionName,
  }: {
    storyId?: number
    storyName?: string
    storyBundleId?: number
    sectionName?: string
  }) => void
}

const RankingItem = ({
  story,
  onClickStoryItem,
  onStoryItemExposed,
}: {
  story: Story
  onClickStoryItem: ({
    storyId,
    storyName,
    storyBundleId,
  }: {
    storyId?: number
    storyName?: string
    storyBundleId?: number
  }) => void
  onStoryItemExposed: ({
    storyId,
    storyName,
    storyBundleId,
  }: {
    storyId?: number
    storyName?: string
    storyBundleId?: number
  }) => void
}) => {
  const storyItemRef = React.useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!storyItemRef.current) return
    const observer = new IntersectionObserver(entries => {
      entries.forEach(
        entry => {
          if (entry.isIntersecting) {
            onStoryItemExposed({
              storyId: story.storyId,
              storyName: story.title,
              storyBundleId: story.bundleId,
            })
          }
        },
        {
          threshold: 1,
        }
      )
    })

    observer.observe(storyItemRef.current)
    return () => observer.disconnect()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div
      key={story.storyId}
      className={styles.storyItemWrapper}
      onClick={() =>
        onClickStoryItem({
          storyId: story.storyId,
          storyName: story.title,
          storyBundleId: story.bundleId,
        })
      }
      ref={storyItemRef}
    >
      <img src={story.mainImageLink} alt={story.title} width={80} height={45} />
      <StoryItemTitleWithRanking
        title={story.title}
        subTitle={story.subTitle}
        ranking={story.ranking || 0}
        isAdult={!!story.isAdult}
      />
    </div>
  )
}

const RankingList = ({
  list,
  index,
  onClickViewMore,
  onClickStoryItem,
  onStoryItemExposed,
}: {
  list?: {
    title?: string
    hasViewMore: boolean
    storyList?: Story[]
  }
  index: number
  onClickViewMore: ({ sectionColumn }: { sectionColumn: number }) => void
  onClickStoryItem: ({
    storyId,
    storyName,
    storyBundleId,
  }: {
    storyId?: number
    storyName?: string
    storyBundleId?: number
  }) => void
  onStoryItemExposed: ({
    storyId,
    storyName,
    storyBundleId,
    sectionName,
  }: {
    storyId?: number
    storyName?: string
    storyBundleId?: number
    sectionName?: string
  }) => void
}) => {
  return (
    <div
      className={styles.storyList}
      data-story-game-section-title={list?.title}
    >
      <div className={styles.rankingListHeader}>
        <div className={styles.rankingListTitle}>{list?.title}</div>
        <div
          className={styles.viewMoreButton}
          onClick={() => onClickViewMore({ sectionColumn: index })}
        >
          {t('storygame_detail_screen_label_view_more')}
        </div>
      </div>
      {list?.storyList?.map(story => (
        <RankingItem
          key={story.storyId}
          story={story}
          onClickStoryItem={onClickStoryItem}
          onStoryItemExposed={args =>
            onStoryItemExposed({
              sectionName: list.title,
              ...args,
            })
          }
        />
      ))}
    </div>
  )
}

// 순위 리스트형 섹션 UI
export const RankingListComponent = ({
  list,
  onClickStoryItem,
  onClickViewMore,
  onStoryItemExposed,
}: IRankingListComponent) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const rankingListLength = list?.length || 0

    scrollContainerRef.current?.style.setProperty(
      '--scrollWidthForMobile',
      `calc(100% * ${rankingListLength} - 2.5rem * ${rankingListLength})`
    )
  }, [list])

  return (
    <div className={styles.container}>
      <div className={styles.scrollContainer} ref={scrollContainerRef}>
        {list?.map((rankingList, index) => (
          <RankingList
            key={rankingList.title}
            list={rankingList}
            index={index}
            onClickViewMore={onClickViewMore}
            onClickStoryItem={args => {
              onClickStoryItem({
                sectionName: rankingList.title,
                ...args,
              })
            }}
            onStoryItemExposed={onStoryItemExposed}
          />
        ))}
      </div>
    </div>
  )
}
