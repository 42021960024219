import React from 'react'
import styles from './StoryItemTitleWithRanking.module.scss'
import { AdultIcon } from '../../../../assets/svgs/AdultIcon'

interface IStoryItemTitleWithRanking {
  title?: string
  subTitle?: string | null
  ranking: number
  isAdult: boolean
}

export const StoryItemTitleWithRanking = ({
  title,
  subTitle,
  ranking,
  isAdult,
}: IStoryItemTitleWithRanking) => {
  return (
    <div className={styles.container}>
      <div className={styles.ranking}>{ranking}</div>
      <div className={styles.contentWrapper}>
        <h2 className={styles.titleWrapper}>
          {isAdult && (
            <div>
              <AdultIcon width={16} height={17} />
            </div>
          )}
          <div className={styles.title}>{title}</div>
        </h2>
        <p className={styles.subTitle}>{subTitle}</p>
      </div>
    </div>
  )
}
