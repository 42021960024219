import React, { SVGProps } from 'react'

export const StorygamePlayIcon = ({ ...props }: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="ic24Play">
        <path
          id="ic16Play"
          d="M5 4.53724C5 3.33176 6.3251 2.59684 7.34665 3.23574L19.2791 10.6985C20.2403 11.2996 20.2403 12.7004 19.2791 13.3015L7.34665 20.7643C6.32509 21.4032 5 20.6682 5 19.4628V4.53724Z"
          fill="white"
          fillOpacity="0.75"
        />
      </g>
    </svg>
  )
}
