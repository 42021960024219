import React, { useEffect } from 'react'
import styles from './CarouselRankItem.module.scss'
import { Story } from '../../../../types/storyGame'
import { AdultIcon } from '../../../../assets/svgs/AdultIcon'

interface ICarouselRankItem {
  story: Story
  onClickStoryItem: ({
    storyId,
    storyName,
    storyBundleId,
  }: {
    storyId?: number
    storyName?: string
    storyBundleId?: number
  }) => void
  onStoryItemExposed: ({
    storyId,
    storyName,
    storyBundleId,
  }: {
    storyId?: number
    storyName?: string
    storyBundleId?: number
  }) => void
}

export const CarouselRankItem = ({
  story,
  onClickStoryItem,
  onStoryItemExposed,
}: ICarouselRankItem) => {
  const storyItemRef = React.useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!storyItemRef.current) return
    const observer = new IntersectionObserver(entries => {
      entries.forEach(
        entry => {
          if (entry.isIntersecting) {
            onStoryItemExposed({
              storyId: story.storyId,
              storyName: story.title,
              storyBundleId: story.bundleId,
            })
          }
        },
        {
          threshold: 1,
        }
      )
    })

    observer.observe(storyItemRef.current)
    return () => observer.disconnect()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div
      className={styles.container}
      onClick={() =>
        onClickStoryItem({
          storyId: story.storyId,
          storyName: story.title,
          storyBundleId: story.bundleId,
        })
      }
      ref={storyItemRef}
    >
      <div className={styles.imageWrapper}>
        <img
          className={styles.image}
          src={story?.mainImageLink}
          alt={'carousel-rank'}
        />
      </div>
      <div className={styles.rankTitleWrapper}>
        <span className={styles.rank}>{story?.ranking || 0}</span>
        <h2 className={styles.titleWrapper}>
          {story.isAdult && <AdultIcon width={20} height={21} />}
          <span className={styles.title}>{story?.title}</span>
        </h2>
      </div>
    </div>
  )
}
