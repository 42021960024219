import React from 'react'
import styles from './CarouselRankComponent.module.scss'
import { Story } from '../../types/storyGame'
import { Carousel } from './common/list/Carousel'
import { CarouselRankItem } from './common/item/CarouselRankItem'
import { t } from '../../utils/translate'

interface ICarouselRankComponent {
  sectionTitle?: string
  storyList?: Story[]
  hasViewMore?: boolean
  onClickStoryItem: ({
    storyId,
    storyName,
    storyBundleId,
  }: {
    storyId?: number
    storyName?: string
    storyBundleId?: number
  }) => void
  onClickViewMore: () => void
  onStoryItemExposed: ({
    storyId,
    storyName,
    storyBundleId,
  }: {
    storyId?: number
    storyName?: string
    storyBundleId?: number
  }) => void
}

// 순위 캐로셀형 섹션 UI
export const CarouselRankComponent = ({
  sectionTitle,
  storyList,
  hasViewMore,
  onClickStoryItem,
  onClickViewMore,
  onStoryItemExposed,
}: ICarouselRankComponent) => {
  return (
    <div
      className={styles.container}
      data-story-game-section-title={sectionTitle}
    >
      <div className={styles.sectionHeader}>
        <div className={styles.sectionTitle}>{sectionTitle}</div>
        {hasViewMore && (
          <div className={styles.viewMoreButton} onClick={onClickViewMore}>
            {t('storygame_detail_screen_label_view_more')}
          </div>
        )}
      </div>
      <Carousel>
        <>
          {storyList?.map(story => (
            <CarouselRankItem
              key={story.storyId}
              story={story}
              onClickStoryItem={onClickStoryItem}
              onStoryItemExposed={onStoryItemExposed}
            />
          ))}
        </>
      </Carousel>
    </div>
  )
}
