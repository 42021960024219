type DEVICE = 'PC' | 'ANDROID' | 'IOS'

export const checkDevice = (): DEVICE => {
  if (typeof window === 'undefined') return 'PC'

  const ua = navigator.userAgent.toLowerCase()

  if (ua.includes('android')) {
    return 'ANDROID'
  } else if (ua.includes('iphone') || ua.includes('ipad')) {
    return 'IOS'
  }

  return 'PC'
}
