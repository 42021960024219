import React from 'react'
import styles from './SliderComponent.module.scss'
import { CarouselComponent } from './CarouselComponent'
import { CAROUSEL_SIZES, Story } from '../../types/storyGame'
import { SlideBannerList } from './common/list/SlideBannerList'

interface ISliderComponent {
  sectionTitle?: string
  storyList?: Story[]
  onClickStoryItem: ({
    storyId,
    storyName,
    storyBundleId,
    url,
  }: {
    storyId?: number
    storyName?: string
    storyBundleId?: number
    url?: string
  }) => void
  onStoryItemExposed: ({
    storyId,
    storyName,
    storyBundleId,
  }: {
    storyId?: number
    storyName?: string
    storyBundleId?: number
  }) => void
}

// 슬라이더형 섹션 UI
export const SliderComponent = ({
  storyList,
  sectionTitle,
  onClickStoryItem,
  onStoryItemExposed,
}: ISliderComponent) => {
  return (
    <div
      className={styles.container}
      data-story-game-section-title={sectionTitle}
    >
      <div className={styles.pcOnly}>
        <SlideBannerList
          sectionTitle={sectionTitle}
          storyList={storyList}
          onClickStoryItem={onClickStoryItem}
          onStoryItemExposed={onStoryItemExposed}
        />
      </div>
      <div className={styles.mobileOnly}>
        <CarouselComponent
          sectionTitle={sectionTitle}
          storyList={storyList}
          size={CAROUSEL_SIZES.Large}
          onClickStoryItem={onClickStoryItem}
          onStoryItemExposed={onStoryItemExposed}
        />
      </div>
    </div>
  )
}
