import { checkDevice } from './checkDevice'

//
// Browser
//
export const CAN_USE_DOM: boolean =
  typeof window !== 'undefined' &&
  typeof window.document !== 'undefined' &&
  typeof window.document.createElement !== 'undefined'

export const IS_SAFARI: boolean =
  CAN_USE_DOM && /Version\/[\d.]+.*Safari/.test(navigator.userAgent)

export const IS_CHROME: boolean =
  CAN_USE_DOM && /^(?=.*Chrome).*/i.test(navigator.userAgent)

//
// OS
//

// iPad 에서 사용하는 특정 속성 확인 (iPadOs 13 이상)
const _IS_IPAD_NEW =
  CAN_USE_DOM &&
  navigator.maxTouchPoints &&
  navigator.maxTouchPoints > 2 &&
  /MacIntel/.test(navigator.platform)
// User-Agent 확인 (iPadOS 13 미만)
const _IS_IPAD_OLD = CAN_USE_DOM && /iPad/.test(navigator.userAgent)
// 터치 이벤트 지원 확인
const _HAS_TOUCH_SUPPORT =
  CAN_USE_DOM && ('ontouchstart' in window || navigator.maxTouchPoints > 0)

export const IS_IPAD: boolean =
  CAN_USE_DOM && (_IS_IPAD_NEW || _IS_IPAD_OLD) && _HAS_TOUCH_SUPPORT

// 지금은 사용 X, 추후 사용되면 주석 해제
// export const IS_ANDROID_TABLET: boolean =
//   CAN_USE_DOM &&
//   /android/i.test(navigator.userAgent) &&
//   !/mobile/i.test(navigator.userAgent) &&
//   _HAS_TOUCH_SUPPORT
// export const IS_ANDROID_OR_IPAD_TABLET = IS_IPAD || IS_ANDROID_TABLET

export const IS_ANDROID: boolean =
  CAN_USE_DOM && /Android/.test(navigator.userAgent)

//
// OS + Browser
//

export const IS_IOS_SAFARI: boolean =
  CAN_USE_DOM && checkDevice() === 'IOS' && IS_SAFARI

export const IS_IPAD_SAFARI: boolean = CAN_USE_DOM && IS_IPAD && IS_SAFARI
export const IS_IPAD_CHROME: boolean = CAN_USE_DOM && IS_IPAD && IS_CHROME

export const IS_ANDROID_CHROME: boolean = CAN_USE_DOM && IS_ANDROID && IS_CHROME
