import React, { useEffect, useRef, useState } from 'react'
import styles from './Carousel.module.scss'
import { ArrowCircleLeftIcon } from '../../../../assets/svgs/ArrowCircleLeftIcon'
import { ArrowCircleRightIcon } from '../../../../assets/svgs/ArrowCircleRightIcon'

interface ICarousel {
  children: JSX.Element[] | JSX.Element
}

export const Carousel = ({ children }: ICarousel) => {
  const carouselItemsRef = useRef<HTMLDivElement | null>(null)
  const [isScroll, setIsScroll] = useState<boolean>(false)
  const [isShowLastItem, setIsShowLastItem] = useState<boolean>(false)

  const handlePrevButtonClick = () => {
    if (carouselItemsRef.current) {
      carouselItemsRef.current.scrollLeft -=
        carouselItemsRef.current.clientWidth
    }
  }

  const handleNextButtonClick = () => {
    if (carouselItemsRef.current) {
      carouselItemsRef.current.scrollLeft +=
        carouselItemsRef.current.clientWidth
    }
  }

  useEffect(() => {
    if (!carouselItemsRef.current) return

    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (
            entry.isIntersecting &&
            carouselItemsRef.current &&
            entry.target instanceof HTMLElement
          ) {
            // 리스트의 마지막 아이템이 화면에 보여지면 next arrow 아이콘을 숨깁니다
            carouselItemsRef.current.lastChild === entry.target
              ? setIsShowLastItem(true)
              : setIsShowLastItem(false)
          }

          // 리스트가 스크롤되면 prev arrow 아이콘을 보여줍니다
          if ((carouselItemsRef.current?.scrollLeft || 0) > 0) {
            setIsScroll(true)
          } else {
            setIsScroll(false)
          }
        })
      },
      {
        threshold: 1,
      }
    )

    // 아이템 각각에 옵저버 부착
    carouselItemsRef.current.childNodes.forEach(node =>
      observer.observe(node as Element)
    )

    return () => {
      observer.disconnect()
    }
  }, [])

  return (
    <div className={styles.container}>
      {isScroll && (
        <ArrowCircleLeftIcon
          className={styles.prevBtn}
          onClick={handlePrevButtonClick}
        />
      )}
      {!isShowLastItem && (
        <ArrowCircleRightIcon
          className={styles.nextBtn}
          onClick={handleNextButtonClick}
        />
      )}
      <div className={styles.carouselItems} ref={carouselItemsRef}>
        {children}
      </div>
    </div>
  )
}
